<template>
  <section id="security" class="pt-8">
  	<v-row class="mx-0" align="center">
  	 	<v-col cols="12" sm="6" md="6" lg="6" class="pr-0">
  	 		<v-row class="mx-0">
  	 			<v-col cols="12" sm="12" md="8" lg="8" class="pb-0" :class="{'offset-4' : $vuetify.breakpoint.mdAndUp}">
  	 				<span class="security-landing d-flex" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">Seguridad e Higiene en tu restaurante</span>
  	 			</v-col>
  	 		</v-row>
  	 		<v-row class="mx-0">
  	 			<v-col cols="12" sm="12" md="8" lg="8" class="px-4 d-flex flex-wrap" :class="{'offset-4' : $vuetify.breakpoint.mdAndUp}">
  	 				<p class="p-landing" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">Ahora tus clientes pueden ir tranquilos a tu restaurante, pues ya pueden ver la carta desde su propio móvil sin tener que compartir la carta impresa con otros comensales.</p>

 						<p class="p-landing" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">Con el módulo de carta electrónica se puede consultar la carta desde cualquier dispositivo, tus clientes pueden ver la carta de tu restaurante desde sus propios dispositivos móviles. No hace falta que los clientes instalen nada en su dispositivo, solo leerán un código QR y ya tienen disponible la carta.</p>
  	 			</v-col>
  	 		</v-row>
  	 	</v-col>
  	 	<v-col cols="12" sm="6" md="6" lg="6" class="pl-0">
	  	 	<v-img
		      :src="require('@/assets/Home/carta.png')"
		      class="white--text"
		      :class="{'mx-auto': $vuetify.breakpoint.smAndDown }"
		      width="95%"
		    ></v-img>
  	 	</v-col>
  	</v-row>
  </section>
</template>
<script>
  export default {
    name: 'Security',

    computed: {
      
    },
  }
</script>
<style lang="sass" scope>

  .security-landing
    font-weight: 700
    line-height: 1.55em
    font-family: Museo-700 !important
    letter-spacing: 0.09em !important
    color: #2c2a2b !important
    &.lg-view 
      font-size: 2.1125rem !important
    &.sm-view 
      font-size: 1.4rem !important
      text-align: center !important
    &.xl-view 
      font-size: 2.3125rem !important

  .p-landing
    line-height: 1.4em
    font-weight: 100
    color: #292626 !important
    font-family: Roboto, sans-serif !important
    &.lg-view 
      font-size: 1.225rem !important
    &.sm-view 
      font-size: 1.0225rem !important
      text-align: center
    &.xl-view 
      font-size: 2rem !important

</style>